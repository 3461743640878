import { useEffect } from 'react'
import { useRouter } from 'next/router'

import useSnackbar from '@hooks/useSnackbar'

const NotFoundPage = () => {
  const router = useRouter()
  const { openSnackbar } = useSnackbar()

  useEffect(() => {
    openSnackbar({
      message: 'Página não encontrada!',
      severity: 'warning',
    })

    router.push('/curriculums')
  }, [router, openSnackbar])

  return null
}

export default NotFoundPage
